import * as React from 'react';
import Button from '@mui/material/Button';
import Spinner from "react-bootstrap/Spinner";
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import './Login.scss'
import { useHistory } from "react-router-dom";
import { reactAppLoginRedirect } from "./auth_helpers";
import { useState, useRef, useEffect } from 'react';
import Preview from './preview';
import { useAuth0 } from "@auth0/auth0-react";
import { ssoRedirectUrl, getMSSSOEnvVariables } from './../../authConfig';
import Notification from '../Notification';

function Copyright(props) {
    return (
        <Typography variant="body2" className="copyRight" align="center" {...props}>
            <span style={{ fontWeight: 'bold' }}>
                {'© '}
                {new Date().getFullYear()}
                {' '}
            </span>
            <Link color="inherit" href="https://www.eldorwal.com/" className="copyRight" target="_blank" rel="noreferrer">
                Eldor-Wal Technologies Inc
            </Link>

            {'.'}
        </Typography>
    );
}

const theme = createTheme({
    palette: {
        primary: {
            main: '#878c33'
        },
        secondary: {
            main: "#122b3b"
        }
    }
});

export default function LoginV2() {
    const history = useHistory()
    const { error, isAuthenticated, isLoading, user, getAccessTokenSilently, handleRedirectCallback, getAccessTokenWithPopup, getIdTokenClaims, loginWithRedirect, loginWithPopup, logout } = useAuth0();


    const handleSubmit = async (event) => {
        event.preventDefault();
        await loginWithRedirect({
            authorizationParams: {
                redirect_uri: ssoRedirectUrl()
            }
        }).then(async (token) => {
            await handleRedirectCallback();
        }).then((err) => {
            console.error(err);
        })

    }

    const handleAboutRedirect = async () => {
        try {


            let res = await fetch('/api/authentication/redirect')
            let RedirectInfo = await res.json()
            if (RedirectInfo.hasOwnProperty('ABOUT_REDIRECT_URL')) {
                window.location.href = RedirectInfo.ABOUT_REDIRECT_URL
            } else {
                throw new Error('No Redirect Url Found')
            }
        }
        catch (e) {
            console.log(e)
            history.push('/')
        }
    }
    const BootstrapDialog = styled(Dialog)(({ theme }) => ({
        '& .MuiDialogContent-root': {
            padding: theme.spacing(2),
        },
        '& .MuiDialogActions-root': {
            padding: theme.spacing(1),
        },
    }));

    const BootstrapDialogTitle = (props) => {
        const { children, onClose, ...other } = props;

        return (
            <>
                {isAuthenticated && (
                    <div>
                        <img src={user.picture} alt={user.name} />
                        <h2>{user.name}</h2>
                        <p>{user.email}</p>
                    </div>
                )}

                {!isLoading && <div className="loader-spin">
                    <Spinner animation="border" style={{ color: '#878C33', position: 'absolute', left: '50%', top: '50%' }} />
                </div>}
                <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
                    {children}
                    {onClose ? (
                        <IconButton
                            aria-label="close"
                            onClick={onClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    ) : null}
                </DialogTitle>
            </>

        );
    };

    BootstrapDialogTitle.propTypes = {
        children: PropTypes.node,
        onClose: PropTypes.func.isRequired,
    };

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 550,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
        textAlign: 'center'
    };

    return (
        <>
            <Notification
                PageID={1} />
            <div className="Main">
                <ThemeProvider theme={theme}>
                    <nav className='topNav'>
                        <div />
                        <div>
                            {/* <a href="https://eldorwal.com" target="_blank" rel="noreferrer">Learn More</a> */}
                            <Button variant="contained" color="primary" sx={{ pl: 5, pr: 5 }} onClick={() => { handleAboutRedirect() }}>About Resolve</Button>
                        </div>
                    </nav>


                    {< Container component="main" maxWidth="sm" className="loginPanel" >
                        <CssBaseline />
                        <Paper
                            elevation={6}
                            sx={{
                                marginTop: 25,
                                padding: 10,
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                            }}
                        >
                            <div className='LoginHeader'>
                                <h2>resolve™ Login</h2>
                                {/* <p>Ever Wanted to Resolve Things?
                                <p>What a Coincidence.</p>
                            </p> */}
                            </div>
                            <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                                <Button
                                    type="submit"
                                    fullWidth
                                    color="primary"
                                    variant="contained"
                                    sx={{ mt: 3, mb: 1 }}
                                >
                                    Sign In
                                </Button>

                            </Box>
                        </Paper>
                    </Container>}

                    <div className='bottomGrid'>
                        <div className="bottomPanel">
                            <div />
                            <Copyright sx={{ mt: 8, mb: 4 }} />
                        </div>
                    </div>
                </ThemeProvider>
            </div >
        </>

    );
}


