import { createRoot } from 'react-dom/client';
import App from './App';
import { Auth0Provider } from '@auth0/auth0-react';
import { ssoRedirectUrl, getMSSSOEnvVariables } from './authConfig';

const onRedirectCallback = (appState) => {

};

getMSSSOEnvVariables().then((config) => {

    const providerConfig = {
        domain: config.CUSTOMDOMAIN,
        clientId: config.CLIENTID,
        cacheLocation: "localstorage",
        response_type: "code",
        useRefreshTokensFallback: true,
        useRefreshTokens: true,
        grant_type: "refresh_token",
        scope: 'openid profile email offline_access read:current_user',
        onRedirectCallback,
        authorizationParams: {
            redirect_uri: ssoRedirectUrl(),
            ...(config.DOMAIN ? { audience: "https://"+ config.DOMAIN + "/api/v2/" } : null),
        },
    };

    const container = document.getElementById('root');
    const root = createRoot(container); // createRoot(container!) if you use TypeScript
    root.render(<Auth0Provider

        {...providerConfig}
    >
        <App />
    </Auth0Provider>,);
});


