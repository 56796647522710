import React, { useEffect } from 'react';
import './Welcome.scss'
import { AnimationOnScroll } from 'react-animation-on-scroll';
import "animate.css/animate.min.css";
import { useHistory } from "react-router-dom";
import "bootstrap/scss/bootstrap.scss";
// images
import logo from './logo_resolve.png'
import graphic1 from './graphic-1.png'
import graphic2 from './graphic-2.png'
import graphic3 from './graphic-3.png'
import graphic4 from './graphic-4.png'
import graphic5 from './graphic-5.png'
import graphic6 from './graphic-6.png'
import graphic7 from './graphic-7.png'

export default function Welcome() {
    const history = useHistory();
    useEffect(() => {
        history.push('/login')
    })
    return (
        <div id="welcome">
            <header className="fixed-top bg-white">
                <div className="container">

                    <nav className="navbar navbar-expand-md">
                        <a href="#" className="navbar-brand mr-0 mr-lg-5"><img src={logo} className="w-100" /></a>
                        <a className="btn btn-primary btn-brown d-md-none" href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank">Book A Demo</a>
                        <button className="navbar-toggler mr-2" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        <div className="collapse navbar-collapse pl-3 pl-xl-5" id="navbarNav">
                            <ul className="navbar-nav">
                                <li className="nav-item">
                                    <a className="nav-link" href="#about">About Us</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#the-difference">Our Difference</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="#why-us">Why Us</a>
                                </li>
                                <li className="nav-item d-md-none">
                                    <a className="nav-link" onClick={() => { history.push('/login') }} href="#">Login</a>
                                </li>
                                <li className="nav-item d-none d-md-block">
                                    <a className="nav-link btn btn-primary btn-brown d-inline-block mr-lg-3 demo" href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank"><span className="d-none d-lg-inline">Book A </span>Demo</a>
                                    <a className="nav-link btn btn-primary btn-brown btn-outline d-inline-block login" onClick={() => { history.push('/login') }} href="#">Login</a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div>

                <hr className="m-0" />
            </header>

            <div className="container pb-5 py-lg-5">
                <div className="row align-items-center py-4">
                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-5 col-8 offset-lg-0 col-xl-6 pr-lg-5">
                        <AnimationOnScroll animateIn="animate__fadeInLeft">
                            <img src={graphic1} className="img-fluid mb-4 mb-lg-0 reveal-right-250" />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-7 col-xl-6">
                        <h1>Alberta Residential Conveyancing Made Easy</h1>
                        <p className="my-3">resolve™ is a revolutionary new app that simplifies the residential and commercial conveyancing process. Create, store and download precedent and lender documents within minutes.</p>
                        <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-primary mt-3 mb-4">Book A Demo</a>
                        <ul className="bulletpoints">
                            <li className="small">No Cost Up Front</li>
                            <li className="small">Free Two Week Trial</li>
                            <li className="small">Unlimited Number of Matters</li>
                        </ul>
                    </div>
                </div>
            </div>

            <div className="bg-blue-dark mb-5">
                <div className="container py-md-5">
                    <div className="row">
                        <div className="col-md-10 offset-md-1 col-xl-8 offset-xl-2 px-3 py-5">
                            <h3 className="color-white text-center">resolve™ gives legal professionals a cost-effective and secure solution to make conveyancing easier than ever before.</h3>
                            <div id="about"></div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-lg-5">
                <div className="row py-4 align-items-center">
                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-5 offset-lg-1 text-center text-lg-left">
                        <AnimationOnScroll animateIn="animate__fadeInLeft">
                            <img src={graphic2} className="w-75 mb-5 mb-lg-0 reveal-right-250" />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-5">
                        <h4 className="text-uppercase">100% Customizable</h4>
                        <h2 className="my-4">Easy to Manage Templates</h2>
                        <p className="mb-5">Create and manage precedent and lender document templates at ease. Document packages are easy to edit and available for download instantly.</p>
                        <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-primary btn-sm mt-1">Book A Demo</a>
                    </div>
                </div>
            </div>

            <hr className="my-5" />

            <div className="container pt-4 py-lg-5">
                <div className="row py-4 py-lg-5 align-items-center">
                    <div className="col-lg-5 offset-lg-1 order-2 order-lg-1">
                        <h4 className="text-uppercase">Highly Secure</h4>
                        <h2 className="my-4">Cloud-Based and Accessible 24/7</h2>
                        <p className="mb-5">Hosted by Amazon Web Services for maximum data security. Transactional information is stored indefinitely and documents are retrievable at any time — even after the Matter is closed.</p>
                        <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-primary btn-sm mt-1">Book A Demo</a>
                    </div>
                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-5 offset-lg-1 text-center text-lg-left order-1 order-lg-2">
                        <AnimationOnScroll animateIn="animate__fadeInRight">
                            <img src={graphic3} className="w-75 mb-5 mb-lg-0 reveal-left-250" />
                        </AnimationOnScroll>
                    </div>
                </div>
            </div>

            <hr className="my-5" />

            <div className="container py-4 py-lg-5">
                <div className="row py-4 py-lg-5 align-items-center">
                    <div className="col-10 offset-1 col-md-8 offset-md-2 col-lg-5 offset-lg-1 text-center text-lg-left">
                        <AnimationOnScroll animateIn="animate__fadeInLeft">
                            <img src={graphic4} className="w-75 mb-5 mb-lg-0 reveal-right-250" />
                        </AnimationOnScroll>
                    </div>
                    <div className="col-lg-5">
                        <h4 className="text-uppercase">Cost-Effective</h4>
                        <h2 className="my-4">No Contract Required</h2>
                        <p className="mb-5">There is no minimum contract period or transaction volume commitment. You are only charged a transaction fee when a Matter is closed, not opened.</p>
                        <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-primary btn-sm mt-1">Book A Demo</a>
                        <div id="the-difference"></div>
                    </div>
                </div>
            </div>

            <div className="bg-triangles mt-4 mt-lg-0">
                <div className="container py-3 py-lg-5">
                    <div className="row">
                        <div className="col-xl-10 offset-xl-1 py-5">
                            <h2 className="mb-5">What Makes Us Different Than Our Competitors?</h2>
                            <div className="row">
                                <div className="col-lg-4">
                                    <h4 className="mb-4">First-Of-Its-Kind Technology</h4>
                                    <p>resolve™ is the first residential conveyance app to use artificial intelligence to scan land title content and employ robotic automation processes to search and register titles.</p>
                                </div>
                                <div className="col-lg-4 py-5 py-lg-0">
                                    <h4 className="mb-4">No SPIN Credentials Required</h4>
                                    <p>Perform title search and registrations without your SPIN account. resolve™ automatically consolidates the payment and attaches the charge to the appropriate matter.</p>
                                </div>
                                <div className="col-lg-4">
                                    <h4 className="mb-4">No Expensive Add-On Charges</h4>
                                    <p>resolve™ makes the conveyance process more affordable by excluding expensive third-party integrations such as title insurance and mortgage instruction processing.</p>
                                    <div id="why-us"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container py-3 py-lg-5">
                <div className="row">
                    <div className="col-xl-10 offset-xl-1 py-5 text-center">
                        <h2 className="mb-5">Why resolve™?</h2>
                        <div className="row">
                            <div className="col-md-4">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={250}>
                                    <img src={graphic5} className="w-50 reveal-up-250" />
                                </AnimationOnScroll>
                                <h3 className="color-black my-3">Locally Made</h3>
                                <p>An Alberta-borne solution for Alberta conveyancers.</p>
                            </div>
                            <div className="col-md-4 py-5 py-md-0">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={500}>
                                    <img src={graphic6} className="w-50 reveal-up-500" />
                                </AnimationOnScroll>
                                <h3 className="color-black my-3">Live Support</h3>
                                <p>Get assistance from our live phone support team.</p>
                            </div>
                            <div className="col-md-4">
                                <AnimationOnScroll animateIn="animate__fadeInUp" delay={750}>
                                    <img src={graphic7} className="w-50 reveal-up-750" />
                                </AnimationOnScroll>
                                <h3 className="color-black my-3">Industry Experts</h3>
                                <p>Backed by over 60 years of conveyance experience.</p>
                            </div>
                        </div>
                        <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-brown mt-5">Book A Demo</a>
                    </div>
                </div>
            </div>

            <div className="bg-blue bg-triangle py-2 py-md-5">
                <div className="container px-4 px-md-0 py-4 py-md-5">
                    <div className="card px-3 py-4 py-md-5">
                        <div className="row">
                            <div className="col-md-10 offset-md-1 px-2 px-md-0 py-md-4">
                                <h2>The Newest & Best Residential Conveyance App For Legal Professionals</h2>
                                <p className="my-4">Easy-to-use and thorough in scope, the resolve™ app offers users the most convenient way to process conveyancing Sale, Purchase and Refinance Matters at a fraction of the cost.</p>
                                <ul className="bulletpoints clearfix">
                                    <li>Add Matter information in any order with zero restrictions</li>
                                    <li className="separated">Purge transactions without incurring extra frees</li>
                                    <li>Retrieve matter documents  at any time — even after it’s closed</li>
                                </ul>
                                <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-brown mt-3 mt-lg-5">Book A Demo</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="bg-triangles bg-blue-dark">
                <div className="container text-center py-5">
                    <h2 className="color-white">Want to<br className="d-md-none" /> learn more?</h2>
                    <p className="color-white">Schedule a FREE one-on-one demo — no commitment necessary. </p>
                    <a href="https://app.squarespacescheduling.com/schedule.php?owner=25253999&appointmentType=33878308" target="_blank" className="btn btn-brown mt-3">Book A Demo</a>
                </div>
            </div>

            <footer>
                <div className="container py-5">
                    <div className="row">
                        <div className="col-lg-10 offset-lg-1">
                            <div className="row align-items-center">
                                <div className="col-lg-3 align-content-center text-center text-lg-left">
                                    A product created by the Eldor-Wal team.
                                </div>
                                <div className="col text-center py-4 py-lg-0">
                                    <img src={logo} className="w-25" />
                                </div>
                                <div className="col-lg-3 text-center text-lg-right">
                                    &copy; 2022 Eldor-Wal Registrations (1987) Ltd.
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
}