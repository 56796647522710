
export const reactAppDomain = () => {
    let hostName = window.location.hostname;
    return hostName.substring(hostName.indexOf(".") + 1);
}

export const ssoRedirectUrl = () => {
     return "https://" + process.env.REACT_APP_LOGIN_URL_HOST + "." + reactAppDomain() + "/profile";
}

export const ssoLogoutRedirectUrl = () => {
    return "https://" + process.env.REACT_APP_LOGIN_URL_HOST + "." + reactAppDomain();
}

export const getMSSSOEnvVariables = async () => {
    try {
        let variablesRaw = await fetch('/api/authentication/getMSSSOVariables');
        let variablesJSON = await variablesRaw.json();
        return variablesJSON
    } catch (e) {
        console.log(e)
        return null;
    }
}

export const loginRequest = {
    scopes: ["User.Read"]
};

export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};



