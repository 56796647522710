import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Button from '@mui/material/Button';
import { BsDownload } from 'react-icons/bs';
import { Checkbox, FormControlLabel } from '@mui/material';
import html2pdf from 'html-to-pdf-js';

const Preview = ({ previewModal, setPreviewModal, selectedLicenseAgreement, handleLogin, userID }) => {
    const [acceptCheckbox, setAcceptCheckbox] = useState(false)

    function getElementFromHTMLString(htmlString) {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlString, 'text/html');
        return doc.body;
    }

    const download = () => {
        const html = `<html>
            <body>
                <style>
                    p {
                        padding: 0;
                        margin: 0;
                    }
                    ol {
                        counter-reset: list-0 list-1 list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
                    }
                    ol li {
                        list-style-type: none;
                        counter-increment: list-0;
                    }
                    ol li::before {
                        display: inline-block;
                        margin-left: -1.5em;
                        margin-right: 0.3em;
                        text-align: right;
                        white-space: nowrap;
                        width: 1.2em;
                        content: counter(list-0,decimal) ". ";
                    }
                    ol li li {
                        counter-reset: list-2 list-3 list-4 list-5 list-6 list-7 list-8 list-9;
                        counter-increment: list-1;
                    }
                    ol li li::before {
                        content: counter(list-1,lower-alpha) ". ";
                    }
                    ol li li li {
                        counter-increment: list-2;
                        counter-reset: list-3 list-4 list-5 list-6 list-7 list-8 list-9;
                    }
                    ol li li li::before {
                        content: counter(list-2,lower-roman) ". ";
                    }
                </style>
            ${selectedLicenseAgreement.LicenseHTMLText}
            </body>
        </html>`
        var element = getElementFromHTMLString(html)
        var opt = {
            margin: 1,
            filename: 'License Agreements.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };
        html2pdf().set(opt).from(element).save();
    }

    const accept = async () => {
        let d = await fetch('/api/authentication/userLicenseAcceptance', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ userID })
        })

        if (d.status === 200) {
            handleLogin()
        }
    }

    return <Modal show={previewModal} onHide={() => { setPreviewModal(false) }} backdrop="static" size='xl'>
        <Modal.Body>
            <div dangerouslySetInnerHTML={{ __html: selectedLicenseAgreement.LicenseHTMLText }} className={"agreement-container dx-htmleditor-content"} />
            <FormControlLabel control={<Checkbox />} label="I have read and understood the license agreement" onChange={(e) => { setAcceptCheckbox(e.target.checked) }} />
        </Modal.Body>
        <Modal.Footer>
            <Button color="secondary" variant="contained" className='me-2' onClick={() => { setPreviewModal(false) }}>I Decline</Button>
            <Button color='primary' variant="contained" onClick={() => { accept() }} disabled={!acceptCheckbox}>I Accept</Button>
            <Button color='primary' variant="outline" onClick={() => { download() }}>
                <BsDownload />
            </Button>
        </Modal.Footer>
    </Modal>
}

export default Preview