export const reactAppDomain = () => {
    let hostName = window.location.hostname;
    return hostName.substring(hostName.indexOf(".") + 1);
}
export const reactAppLoginRedirect = () => {
    if ( process.env.REACT_APP_LOGIN_REDIRECT_HOST ) {
        return window.location.protocol +"//" + process.env.REACT_APP_LOGIN_REDIRECT_HOST + "." + reactAppDomain()
    }
    else {
        return "http://localhost:3000";
    }
}

export const reactAppLogOutRedirect = () => {
    if ( process.env.REACT_APP_LOGIN_REDIRECT_HOST ) {
        return window.location.protocol +"//" + process.env.REACT_APP_POST_LOGOUT_REDIRECT_URI + "." + reactAppDomain()
    }
    else {
        return "http://localhost:3001";
    }
}