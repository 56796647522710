import { useEffect, useState } from "react";
import './index.scss'
import { MdClose, MdInfoOutline, MdOutlineCheckCircle } from "react-icons/md";
import { IoMdCloseCircleOutline } from "react-icons/io";

const Notification = (props) => {
    const [notification, setNotification] = useState({})

    useEffect(() => {
        if (props.PageID > 0) {
            const eventSource = new EventSource(`/api/dashboard/notification/${props.PageID}`);

            eventSource.onmessage = (event) => {
                const newEvent = JSON.parse(event.data);
                const msg = newEvent.msg

                const notArrStr = localStorage.getItem('notification')
                if (!notArrStr) {
                    localStorage.setItem('notification', '[]')
                }
                else {
                    const notArr = JSON.parse(notArrStr)
                    if (notArr.indexOf(msg.BannerNotificationID) === -1) {
                        setNotification(msg)
                    }
                    else {
                        setNotification({})
                    }
                }
            };

            eventSource.onerror = (err) => {
                setNotification('EventSource failed:', err);
            };

            return () => {
                eventSource.close();
            };
        }
    }, []);

    const className = (style) => {
        switch (style) {
            case 'Yellow Exclamation':
                return "yellow"
            case 'Red Critical':
                return 'red'
            case 'Green Confirmation':
                return 'green'
            default:
                return "blue"
        }
    }

    const icon = (style) => {
        switch (style) {
            case 'Yellow Exclamation':
                return <MdInfoOutline />
            case 'Red Critical':
                return <IoMdCloseCircleOutline />
            case 'Green Confirmation':
                return <MdOutlineCheckCircle />
            default:
                return <MdInfoOutline />
        }
    }

    const close = (BannerNotificationID) => {
        setNotification({})

        const notArrStr = localStorage.getItem('notification')
        const notArr = JSON.parse(notArrStr)
        notArr.push(BannerNotificationID)
        localStorage.setItem('notification', JSON.stringify(notArr))
    }

    return (
        notification.Message && <div className={`position-absolute notification ${className(notification.NotificationStyle)}`} style={{ width: notification.Message.length * 5 }}>
            {icon(notification.NotificationStyle)}
            <span>{notification.Message}</span>
            <span className="ms-auto"><MdClose onClick={() => close(notification.BannerNotificationID)} className="close" /></span>
        </div>
    )
}

export default Notification;