import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { useEffect } from "react";
import Cookies from 'js-cookie';
import axios from 'axios';
import { reactAppLoginRedirect } from "./auth_helpers";

const Profile = () => {
    const { error,
        isAuthenticated,
        isLoading,
        user,
        getAccessTokenSilently,
        getAccessTokenWithPopup,
        getIdTokenClaims,
        loginWithRedirect,
        loginWithPopup,
        handleRedirectCallback,
        logout, } = useAuth0();


    useEffect(() => {

        if (isAuthenticated) {
            const getToken = async () => {
                const token = await getAccessTokenSilently({
                    ignoreCache: true,
                    scope: "openid offline_access",
                    grant: "refresh_token",
                    detailedResponse: true,
                });
                let idToken = token.id_token;
                Cookies.set('token', idToken, {
                    expires: token.expires_in, secure: true
                });
                Object.keys(localStorage).reduce(function (obj, str) {
                    obj[str] = localStorage.getItem(str);
                    if (JSON.parse(localStorage.getItem(str)).body?.refresh_token) {
                        Cookies.set('refreshToken', JSON.parse(localStorage.getItem(str)).body?.refresh_token, {
                            expires: JSON.parse(localStorage.getItem(str)).body?.expires_in, secure: true
                        });
                    }
                    return obj
                }, {});

                window.location.href = reactAppLoginRedirect();
            }

             getToken();

        }

    }, [isAuthenticated])

    if (isLoading) {
        return <div>Loading ...</div>;
    }

    return (
        isAuthenticated && !isLoading && (
            <div>
                <img src={user.picture} alt={user.name} />
                <h2>{user.name}</h2>
                <p>{user.email}</p>
            </div>
        )
    );
};

export default Profile;