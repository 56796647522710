import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import ForgotPassword from "./components/ForgotPassword";
import Register from "./components/Register";
import "./App.scss";
import Welcome from "./components/Welcome";
import ActiveAccount from "./components/ActiveAccount";
import { Redirect } from "react-router-dom/cjs/react-router-dom.min";
import LoginV2 from '../src/components/Login/login';
import Profile from '../src/components/Login/profile';

export default function App() {
    
    return (
        <div className="App">
            <Router>
                <Switch>
                    <Route path="/forgotpassword/:hash" component={ForgotPassword} />
                    <Route path="/activateaccount/:hash" component={ActiveAccount} />
                    <Route path="/login" component={LoginV2} />
                    <Route path="/register" component={Register} />
                    <Route path="/welcome" component={Welcome}/>
                    <Route path="/profile" component={Profile}/>
                    <Redirect from="*" to="/login" />
                </Switch>
            </Router>
        </div>
    );
}
