import React, { useEffect, useState } from 'react';
import { useParams } from "react-router-dom";
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import EmailIcon from '@mui/icons-material/Email';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle'
import { reactAppLoginRedirect } from "../Login/auth_helpers";
import Preview from '../Login/preview';
import { useHistory } from "react-router-dom";

function Copyright(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {'© '}
            <Link color="inherit" href="https://www.eldorwal.com/">
                {new Date().getFullYear()}  Eldor-Wal Technologies Inc
            </Link>{' '}

            {'.'}
        </Typography>
    );
}

const theme = createTheme();



export default function ForgotPassword() {
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState('')
    const [userID, setUserID] = useState(0)
    const [error, setError] = useState(false)
    const [resetError, setResetError] = useState(false)
    const [success, setSuccess] = useState(false)
    const [previewModal, setPreviewModal] = useState(false)
    const [selectedLicenseAgreement, setSelectedLicenseAgreement] = useState(false)

    let history = useHistory();

    useEffect(() => {
        const checkToken = async () => {
            await fetch('/api/authentication/forgotpassword/' + params.hash, {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' }
            })
                .then(async r => {
                    if (r.status === 200) {
                        let res = await r.json()
                        setEmail(res.SentToEmail)
                        setUserID(res.UserID)
                    }
                    else {
                        setError(true)
                    }
                })
        }
        checkToken()
    }, [])

    const params = useParams();



    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);

        const passwordConfirm = data.get('password-confirm')
        const password = data.get('password')

        if (password === "" || passwordConfirm === "") {
            setResetError("Password cannot be empty")
            return
        }

        if (password !== passwordConfirm) {
            setResetError("Password Mismatch")
            return
        }

        await fetch('/api/authentication/resetpassword', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ passwordConfirm, password, userID, linkHash: params.hash })
        })
            .then(async d => {
                if (d.status === 200) {
                    setSuccess(true)
                }
                else {
                    const r = await d.json()
                    setResetError(r)
                }
            })

        var CryptoJS = require("crypto-js");
        var pass = CryptoJS.AES.encrypt(password, 'eldorwal').toString();
        setPassword(pass)
        const d = await fetch('/api/authentication/login', {
            method: 'POST',
            mode: 'same-origin',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password: pass })
        })

        if (d.status === 200) {
            window.location.href = reactAppLoginRedirect()
        }
        if (d.status === 203) {
            let r = await d.json()
            setUserID(r.UserID)
            setSelectedLicenseAgreement(r)
            setPreviewModal(true)
        }
        else {
            setResetError(await d.json())
        }
    };

    const handleLogin = async () => {
        let loginR = await fetch('/api/authentication/login', {
            method: 'POST',
            mode: 'same-origin',
            credentials: 'include',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email, password })
        })
        if (loginR.status === 200) {
            window.location.href = reactAppLoginRedirect()
        }
        else {
            setResetError(await loginR.json())
            setPreviewModal(false)
        }
    }
    let returnHtml = ""
    if (error) {
        returnHtml =
            <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                <strong>Oops. Something went wrong. Please request a password reset again and use the new link that is emailed to you.</strong>
            </Alert>
    }
    else {
        returnHtml =
            <div>
                <Typography component="h1" variant="h5">
                    Reset Password
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <Chip icon={<EmailIcon />} label={email} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                name="password"
                                label="Password"
                                type="password"
                                id="password"
                                autoComplete="new-password"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                required
                                fullWidth
                                id="password-confirm"
                                type="password"
                                label="Confirm Password"
                                name="password-confirm"
                                autoComplete="password-confirm"
                            />
                        </Grid>
                    </Grid>
                    {resetError && <Alert severity="error">
                        Error — <strong>{resetError}</strong>
                    </Alert>}
                    {success && <Alert severity="success">
                        Success — <strong>Reset Password Successfully!</strong>
                    </Alert>}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}
                    >
                        Reset Password
                    </Button>
                    <Grid container justifyContent="flex-end">
                        <Grid item>
                            <Link href="/login" variant="body2">
                                Already have an account? Sign in
                            </Link>
                        </Grid>
                    </Grid>
                </Box>
            </div>
    }
    const handlePreviewModalSet = (bool) => {
        setPreviewModal(bool)
        if(!bool){
            history.push('/login')
        }
    }
    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    {returnHtml}
                </Box>
                <Copyright sx={{ mt: 5 }} />
            </Container>
            {previewModal && <Preview
                previewModal={previewModal}
                setPreviewModal={handlePreviewModalSet}
                selectedLicenseAgreement={selectedLicenseAgreement}
                handleLogin={handleLogin}
                userID={userID}
            />}
        </ThemeProvider>
    )
}